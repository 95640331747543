.c-category {
    margin-top: mobileRatio(180);

    &__title {
        position: relative;
        padding: 0 gutter();

        @include media('lg') {
            margin-top: vw(80px);

            @include susy-use($desk) {
                padding: 0 gutter();
            }
        }

        @include media('xl') {
            margin-top: 80px;
        }
    }

    &__type {
        position: absolute;
        top: calc(100% + #{mobileRatio(37)});
        left: gutter() * -1.3;

        @include media('sm') {
            left: -2px;
        }

        @include media('lg') {
            left: -5px;
        }

        .o-sub-title {
            display: block;
            line-height: 1.1;
        }
    }

    &__slider {
        margin-top: mobileRatio(115);
        margin-left: gutter();
        width: span(5);
        position: relative;

        @include media('lg') {
            margin-top: vw(75px);

            @include susy-use($desk) {
                margin-left: span(1 wider);
                width: span(8);
            }
        }

        @include media('xl') {
            margin-top: 75px;
        }

        &.has-no-title {
            margin-top: mobileRatio(100);

            @include media('lg') {
                margin-top: vw(50px);
            }
        }

        &.has-no-types {
            margin-top: 1%;

            @include media('lg') {
                margin-top: vw(25px);
            }
        }

        .c-category__button {
            position: absolute;
            top: -1%;
            right: span(1 wide of 5 narrow) * -1;
            transform: translateY(-100%);

            @include media('lg') {
                top: -3%;
                font-size: vw(30px);

                @include susy-use($desk) {
                    right: span(3 wider of 8 narrow) * -1;
                }
            }

            @include media('xl') {
                font-size: 30px;
            }
        }
    }
}
