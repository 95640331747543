.c-product-card {
    &__info {
        margin-top: mobileRatio(63);

        @include media('lg') {
            position: relative;
        }

        .o-paragraph {
            font-size: mobileRatio(40);
            line-height: em(40px, 50px);

            @include media('lg') {
                font-size: vw(20px);
            }

            @include media('xl') {
                font-size: 20px;
            }
        }
    }

    &__name {
        @include media('lg') {
            @include susy-use($desk) {
                width: span(3 of 4 narrow);
            }
        }
    }

    &__type {
        color: $gray;
    }

    &__price {
        @include media('lg') {
            position: absolute;
            top: 0;
            right: 0;

            @include susy-use($desk) {
                width: span(1 of 4 narrow);
                text-align: left;
            }
        }
    }
}
