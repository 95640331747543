.hero {
    padding: (gutter() * 2) gutter() gutter();
    padding-bottom: 0;

    @include media('sm') {
        padding: 0;
    }

    @include media('lg') {
        position: relative;
    }

    &__image {
        position: relative;
    }

    &__tag {
        display: inline-block;
        position: absolute;
        top: 2.3%;
        left: 2.1%;
        line-height: em(34px, 55px);
        font-size: mobileRatio(34);
        background-color: $white;
        border-radius: em(34px, 50px) / 2;
        padding: 0 mobileRatio(21);
        min-width: mobileRatio(311);
        box-sizing: border-box;
        font-weight: 700;

        @include media('sm') {
            top: vw(100px);
            left: gutter();
        }

        @include media('lg') {
            @include susy-use($desk) {
                left: gutter();
            }
        }

        @include media('xl') {
            top: 100px;
        }
    }

    &__copy {
        margin-top: gutter(of 6 narrow) * 3;

        @include media('sm') {
            padding: 0 gutter();
            margin-top: gutter(of 6 narrow);
        }

        @include media('lg') {
            padding: 0;
            position: absolute;
            bottom: vw(40px);

            @include susy-use($desk) {
                left: gutter();
            }
        }

        .o-title {
            @include media('lg') {
                color: $white;
            }
        }

        .o-button {
            margin-top: gutter(of 6 narrow) * 3;

            @include media('sm') {
                margin-top: gutter(of 6 narrow) * 1.5;
            }

            @include media('lg') {
                @include susy-use($desk) {
                    margin-top: gutter() * 6;
                }
            }
        }
    }
}
