// colors
$black: #000;
$white: #fff;
$gray: #b1b3b3;
$sand: #bfb8af;

// fonts
$main-font: Helvetica, Arial, sans-serif;

// media breakpoints
$media: (
    'sm': 640px,
    'lg': 1024px,
    'xl': 1920px,
);

// susy config
$susy: (
    'columns': susy-repeat(6),
    'gutters': 20 / 155,
    'spread': 'narrow',
    'container-spread': 'wider',
);

$desk: (
    'columns': susy-repeat(12),
    'gutters': 20 / 135,
    'spread': 'narrow',
    'container-spread': 'wider',
);
