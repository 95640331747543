.c-slider {
    $self: &;

    &.community {
        .swiper-slide {
            @include media('lg') {
                box-sizing: border-box;

                @include susy-use($desk) {
                    padding-right: span(1 of 8 narrow) / 2;
                }
            }
        }
    }

    .swiper {
        &-container {
            overflow: initial;
        }

        &-scrollbar {
            height: 4px;
            background: $gray;
            border-radius: 0;
            margin-top: mobileRatio(86);
            margin-left: span(1 wide of 5 narrow);
            width: span(3 wide of 5 narrow) + (span(1 of 5 narrow) / 2);

            @include media('lg') {
                margin-top: vw(68px);

                @include susy-use($desk) {
                    margin-left: span(3 wide of 8 narrow);
                    width: span(4 of 8 narrow);
                }
            }

            @include media('xl') {
                margin-top: 68px;
            }

            &.hidden {
                display: none;
            }

            &-drag {
                background: $black;
                border-radius: 0;
            }
        }
    }

    &__button {
        display: none;

        @include media('lg') {
            display: block;
            position: absolute;
            z-index: 2;
            bottom: vw(190px);
        }

        @include media('xl') {
            bottom: 190px;
        }

        &.is-left {
            left: span(1 wide of 8 narrow) * -1;
        }

        &.is-right {
            right: span(3 wide of 8 narrow) * -1;
        }

        .o-button {
            transition: opacity 150ms linear;

            &.swiper-button-disabled {
                opacity: 0;
            }
        }
    }
}
