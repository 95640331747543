.o-button {
    @include button-reset;
    display: inline-block;
    font-weight: 200;
    text-align: left;
    border-radius: 1.5em;
    min-width: mobileRatio(288);
    line-height: 2em;
    font-size: mobileRatio(40);
    padding: 0 14px;

    @include media('lg') {
        font-size: vw(30px);
        padding: 0 18px;
        min-width: vw(203px);
        line-height: em(30px, 50px);
    }

    @include media('xl') {
        font-size: 30px;
        padding: 0 18px;
        min-width: 203px;
    }

    &.is-bigger {
        @include media(null, 'lg') {
            min-width: mobileRatio(330);
            border-radius: 1.5em;
            font-size: mobileRatio(50);
            line-height: em(40px, 95px);
        }

        @include media('lg') {
            line-height: em(30px, 45px);
        }
    }

    &.is-black {
        background-color: $black;
        color: $white;

        &:hover {
            font-weight: 700;
        }
    }

    &.is-sand {
        background-color: $sand;
        color: $white;

        &:hover {
            font-weight: 700;
        }
    }

    &.is-white {
        background-color: $white;
        color: $black;
        transition: 150ms;

        &:hover {
            background-color: $black;
            color: $white;
        }
    }

    &.has-no-border {
        color: $black;
        font-size: mobileRatio(44);
        min-width: initial;
    }

    &.is-round {
        display: none;
        background-color: $black;
        padding: 0;
        min-width: initial;
        display: block;
        position: relative;
        width: 40px;

        @include media('lg') {
            width: vw(66px);
        }

        @include media('xl') {
            width: 66px;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 100%;
        }

        &::after {
            content: '';
            display: block;
            width: 20%;
            height: 20%;
            border-left: 1px solid $white;
            border-top: 1px solid $white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-30%, -50%) rotate(-45deg);

            @include media('lg') {
                border-left: 0.2vw solid $white;
                border-top: 0.2vw solid $white;
            }

            @include media('xl') {
                border-left: 3px solid $white;
                border-top: 3px solid $white;
            }
        }

        &.is-right {
            &::after {
                transform: translate(-70%, -50%) rotate(135deg);
            }
        }
    }
}
