.u-grid-helper {
    @include grid;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 10000;
    pointer-events: none;
    width: span(6);
    right: 0;
    margin: auto;

    @include media('sm') {
        width: 100%;
    }
}

.u-text-light {
    font-weight: 200;
}

.u-text-bold {
    font-weight: 700;
}
