.discover {
    // TODO remove pointer events when urls are available
    pointer-events: none;
    padding: 0 gutter();
    margin-top: gutter() * 4;

    @include media('sm') {
        margin-top: gutter() * 3;
    }

    @include media('lg') {
        margin-top: vw(49px);

        @include susy-use($desk) {
            padding: 0 gutter();
        }
    }

    &__title {
        display: none;

        @include media('lg') {
            display: block;
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include media('lg') {
            margin-top: vw(30px);
        }

        @include media('xl') {
            margin-top: 30px;
        }
    }

    &__item {
        width: span(3 of 6 narrow);

        @include media('lg') {
            @include susy-use($desk) {
                width: span(4 of 12 narrow);
            }
        }

        &:last-child {
            @include media(null, 'lg') {
                margin-top: gutter(of 6 narrow);
                width: 100%;
            }
        }
    }
}
