.c-discover-card {
    $self: &;
    position: relative;
    display: block;

    &.is-wide {
        #{$self}__button {
            @include media(null, 'lg') {
                left: 3.6%;
            }
        }
    }

    &__image {
        img {
            width: 100%;
        }
    }

    &__button {
        position: absolute;
        left: 8.8%;
        bottom: 6.8%;

        @include media('lg') {
            bottom: 8%;
            left: 8%;
        }
    }
}
