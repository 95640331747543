.l-main {
    overflow: hidden;
    font-family: $main-font;
    font-weight: 200;
    padding: 0 gutter();

    @include media('sm') {
        padding: 0;
    }
}
