@mixin media($min: null, $max: null) {
    $min-type: type-of($min);
    $max-type: type-of($max);

    @if $min-type == string {
        @if $min == ss {
            $min: '(min-width: #{map-get($media, ss)})';
        } @else if $min == sm {
            $min: '(min-width: #{map-get($media, sm)})';
        } @else if $min == md {
            $min: '(min-width: #{map-get($media, md)})';
        } @else if $min == lg {
            $min: '(min-width: #{map-get($media, lg)})';
        } @else if $min == xl {
            $min: '(min-width: #{map-get($media, xl)})';
        } @else {
            @warn "media mixin supports: sm, md, lg, xl";
        }
    } @else if $min-type == number {
        $min: '(min-width: #{$min})';
    }

    @if $max-type == string {
        @if $max == ss {
            $max: '(max-width: #{map-get($media, ss) - 1})';
        } @else if $max == sm {
            $max: '(max-width: #{map-get($media, sm) - 1})';
        } @else if $max == md {
            $max: '(max-width: #{map-get($media, md) - 1})';
        } @else if $max == lg {
            $max: '(max-width: #{map-get($media, lg) - 1})';
        } @else if $max == xl {
            $max: '(max-width: #{map-get($media, xl) - 1})';
        } @else {
            @warn "media mixin supports: sm, md, lg, xl";
        }
    } @else if $max-type == number {
        $max: '(max-width: #{$max})';
    }

    $query: false;
    @if $min and $max {
        $query: '#{$min} and #{$max}';
    } @else if $min {
        $query: '#{$min}';
    } @else if $max {
        $query: '#{$max}';
    }

    @if (not $query) {
        @warn "Invalid media query";
    } @else {
        @media #{$query} {
            @content;
        }
    }
}

@mixin susy-use($config) {
    $config: susy-compile($config);
    $global: $susy;
    $susy: map-merge($susy, $config) !global;

    @content;

    $susy: $global !global;
}

@mixin grid {
    background: svg-grid($susy, rgba($color: cyan, $alpha: 0.4)) no-repeat
        scroll;

    @media (min-width: map-get($media, 'lg')) {
        background: svg-grid($desk, rgba($color: cyan, $alpha: 0.4)) no-repeat
            scroll;
    }
}

@function vw($px-vw, $base-vw: 1920px) {
    @return ($px-vw * 100vw) / $base-vw;
}

@mixin button-reset {
    appearance: none;
    border: none;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
}

@function mobileRatio($value) {
    @return ($value * 375 / 1000) * 1px;
}

@function em($font-size: 16, $line-height: 16) {
    @if (unitless($line-height)) {
        $line-height: $line-height * 1px;
    }

    @if (unitless($font-size)) {
        $font-size: $font-size * 1px;
    }

    @return $line-height / $font-size * 1em;
}
