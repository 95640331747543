.o-title {
    margin: 0;
    font-size: mobileRatio(120);
    line-height: em(120px, 107px);
    letter-spacing: -0.04em;

    @include media('lg') {
        font-size: vw(101px);
        line-height: em(101px, 91px);
    }

    @include media('xl') {
        font-size: 101px;
    }
}

.o-sub-title {
    margin: 0;
    font-size: mobileRatio(60);
    font-weight: 200;
    line-height: em(60px, 96px);

    @include media('lg') {
        font-size: vw(40px);
        line-height: em(40px, 48px);
    }

    @include media('xl') {
        font-size: 40px;
    }

    &.is-bold {
        font-weight: 700;
        text-transform: uppercase;
    }
}

.o-paragraph {
    margin: 0;
    font-size: mobileRatio(36);
    line-height: em(36px, 50px);

    @include media('lg') {
        font-size: vw(20px);
        line-height: em(20px, 30px);
    }

    @include media('xl') {
        font-size: 20px;
    }
}
