.c-community-card {
    &__image {
        position: relative;

        .o-button {
            position: absolute;
            bottom: 3.3%;
            right: 2.6%;

            @include media('lg') {
                bottom: 5%;
                right: 7.4%;
            }
        }
    }

    &__info {
        margin-top: mobileRatio(63);

        .o-paragraph {
            font-size: mobileRatio(40);
            line-height: em(40px, 50px);

            @include media('lg') {
                font-size: vw(20px);
            }

            @include media('xl') {
                font-size: 20px;
            }
        }
    }

    &__user {
        @include media('lg') {
            @include susy-use($desk) {
                width: span(3 of 4 narrow);
            }
        }
    }

    &__handle {
        color: $gray;
    }
}
