.video {
    margin: mobileRatio(250) 0;

    @include media('lg') {
        margin: vw(86px) 0;
    }

    @include media('xl') {
        margin: 86px 0;
    }

    &__asset {
        position: relative;
        width: 100% + (gutter(of 6 narrow) * 2);
        margin-left: gutter(of 6 narrow) * -1;
        background-color: $black;
        cursor: pointer;
        overflow: hidden;

        @include media('lg') {
            width: 100%;
            margin: 0;
        }

        &::after {
            pointer-events: none;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0;
            height: 0;
            transform: translate(-50%, -50%) rotate(90deg);
            transition: transform 0.6s cubic-bezier(0.65, 0, 0.35, 1);
            content: '';
            z-index: 2;
            border-right: 15px solid transparent;
            border-bottom: 25px solid $white;
            border-left: 15px solid transparent;

            @include media('lg') {
                border-right: 40px solid transparent;
                border-bottom: 60px solid $white;
                border-left: 40px solid transparent;
            }
        }

        video {
            width: 100%;
            display: block;
            transition: transform 0.6s cubic-bezier(0.65, 0, 0.35, 1),
                opacity 0.6s linear;
        }

        &:hover {
            &::after {
                transform: translate(-50%, -50%) rotate(90deg) scale(0.6);
            }

            video {
                transform: scale(1.2);
                opacity: 0.6;
            }
        }
    }

    &__info {
        margin-top: mobileRatio(55);
        padding: 0 gutter();

        @include media('lg') {
            margin-top: vw(46px);

            @include susy-use($desk) {
                padding: 0 gutter();
            }
        }

        @include media('xl') {
            margin-top: 46px;
        }

        .o-title {
            font-size: mobileRatio(90);
            font-weight: 400;

            @include media('lg') {
                font-size: vw(64px);
            }

            @include media('xl') {
                font-size: 64px;
            }
        }

        .o-paragraph {
            margin-top: mobileRatio(60);

            @include media('sm') {
                width: span(3 of 6 narrow);
            }

            @include media('lg') {
                margin-top: vw(46px);

                @include susy-use($desk) {
                    width: span(3 wide of 12 narrow) +
                        (span(1 of 12 narrow) / 2);
                }
            }

            @include media('xl') {
                margin-top: 46px;
            }
        }
    }

    &__links {
        margin-top: mobileRatio(50);

        @include media('lg') {
            margin-top: vw(27px);
        }

        @include media('xl') {
            margin-top: 27px;
        }

        .o-button {
            &:not(:first-child) {
                margin-left: gutter(of 6 narrow);

                @include media('lg') {
                    @include susy-use($desk) {
                        margin-left: gutter(of 12 narrow);
                    }
                }
            }
        }
    }
}

.modal {
    $self: &;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.6s linear;

    &.is-open {
        opacity: 1;
        pointer-events: all;

        #{$self}__video {
            transform: scale(1);
        }
    }

    &__video {
        position: relative;
        max-width: 80%;
        max-height: 80%;
        z-index: 2;
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: $black, $alpha: 0.75);
    }

    &__close {
        position: absolute;
        top: vw(20px);
        right: vw(20px);
        width: 50px;
        height: 50px;
        cursor: pointer;
        z-index: 2;
        transition: transform 0.6s cubic-bezier(0.65, 0, 0.35, 1);

        &:hover {
            transform: scale(0.7) rotate(180deg);
        }

        &::before,
        &::after {
            content: '';
            display: block;
            width: 25px;
            height: 4px;
            background-color: $white;
            position: absolute;
            top: 50%;
            left: 50%;
        }

        &::before {
            transform: translate(-50%, -50%) rotate((45deg));
        }

        &::after {
            transform: translate(-50%, -50%) rotate((-45deg));
        }
    }
}
